import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


var flights = Array.from(document.getElementsByClassName('flight')) as HTMLElement[];

flights.map((flight: HTMLElement) => {
  let getProductsFunction:string|null = flight.getAttribute("getProductsFunction");

  if(getProductsFunction != null){
    ReactDOM.render(
      <React.StrictMode>
        <App getProductsFunction={getProductsFunction} />
      </React.StrictMode>,
      flight
    );
  }

});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
