import React from 'react';
import * as Flight from './Category'
import './App.css';

interface Point {
  x:number
  y:number
};

interface ProductButton {
   product: Flight.Product
   score: number
   location: Point
   size: {w:number, h:number}
   shrinkBy: number
   visible: boolean
}

interface PlaceHolderButton {
   location: Point
   size: {w:number, h:number}
}

interface IProps {
   getProductsFunction: string
}
interface IState {
   timeStamp?: number
}



class App extends React.Component<IProps, IState> {
  
  private flight: Flight.Flight;
  //private selectedProduct: Flight.Product | null;
  private currentProductDelta: number;
  private focusedProductID: number| null;
  private productButtons: ProductButton[];
  private svgRef = React.createRef<SVGSVGElement>();
  private svg: SVGSVGElement | null;
  private isPointerDown: boolean;
  private didPointerMove: boolean;
  private didPointerMoveRight: boolean;
  private originalSVGMousePoint: Point;
  private tileWidth: number;
  private tileHeight: number;
  private tileInterval: number;
  private shrinkBy: number;
  private imageInset: number;

  constructor(props: any) {
    super(props);
    
    this.state = {
       timeStamp: Date.now()
    }

   this.isPointerDown = false;
   this.didPointerMove = false;
   this.didPointerMoveRight = false;
   this.originalSVGMousePoint = {x: 0, y:0};
   this.tileWidth = 30;
   this.tileHeight = 40;
   this.tileInterval = 35;
   this.shrinkBy = 2.5;
   this.imageInset = 2.5;
   this.svg = null;

   this.currentProductDelta = 0;
   this.focusedProductID = null;

   this.productButtons = [];

   //

   this.flight = {
      selectedProductID: null,
      focusedProductID: null,
      products: [], 
      showScores: false,
      canClickSelectedProduct: false,
      highlightSelectedProduct: false
   };
   
  }


  componentDidMount(){



    if(this.svgRef.current){

      this.svg = this.svgRef.current;

      // Add all mouse events listeners fallback
      this.svg.addEventListener('mousedown', (e: MouseEvent) => this.onPointerDown(e), false); // Pressing the mouse
      this.svg.addEventListener('mouseup', (e: MouseEvent)  => this.onPointerUp(e), false); // Releasing the mouse
      this.svg.addEventListener('mouseleave', (e: MouseEvent)  => this.onPointerLeave(e), false); // Mouse gets out of the SVG area
      this.svg.addEventListener('mousemove', (e: MouseEvent)  => this.onPointerMove(e), false); // Mouse is moving

      // Add all touch events listeners fallback
      this.svg.addEventListener('touchstart', (e: TouchEvent) => this.onPointerDown(e), false); // Finger is touching the screen
      this.svg.addEventListener('touchend', (e: TouchEvent) => this.onPointerUp(e), false); // Finger is no longer touching the screen
      this.svg.addEventListener('touchmove', (e: TouchEvent) => this.onPointerMove(e), false); // Finger is moving
    }

    this.productsChanged();

    document.addEventListener('productsChanged', (evt: any) => this.productsChanged());
    document.addEventListener('focusedProductChanged', (evt: any) => this.focusedProductChanged((evt as CustomEvent).detail));
  }

  componentWillUnmount(): void {
     
   document.removeEventListener('productsChanged', (evt: any) => this.productsChanged());
   document.removeEventListener('focusedProductChanged', (evt: any) => this.focusedProductChanged((evt as CustomEvent).detail));

   if(this.svg){
      // Add all mouse events listeners fallback
      this.svg.removeEventListener('mousedown', (e: MouseEvent) => this.onPointerDown(e), false); // Pressing the mouse
      this.svg.removeEventListener('mouseup', (e: MouseEvent)  => this.onPointerUp(e), false); // Releasing the mouse
      this.svg.removeEventListener('mouseleave', (e: MouseEvent)  => this.onPointerLeave(e), false); // Mouse gets out of the SVG area
      this.svg.removeEventListener('mousemove', (e: MouseEvent)  => this.onPointerMove(e), false); // Mouse is moving
    
      // Add all touch events listeners fallback
      this.svg.removeEventListener('touchstart', (e: TouchEvent) => this.onPointerDown(e), false); // Finger is touching the screen
      this.svg.removeEventListener('touchend', (e: TouchEvent) => this.onPointerUp(e), false); // Finger is no longer touching the screen
      this.svg.removeEventListener('touchmove', (e: TouchEvent) => this.onPointerMove(e), false); // Finger is moving
   }
  }

  productsChanged(){

   this.flight = window.Function("javascript: return " + this.props.getProductsFunction )?.();

   this.productButtons = [];

   if(this.flight.products.length > 0){

      //this.products.products = this.products.products.sort((a,b) => b.order - a.order);

      this.flight.products.map((p:Flight.Product, i: number) => {
   
         let productButton: ProductButton = {
            product: p,
            score: p.score,
            location: {x: (i * this.tileInterval), y:2.5},
            size: {w:this.tileWidth, h:this.tileHeight},
            shrinkBy: this.shrinkBy,
            visible: false
         }
         this.productButtons.push(productButton);
      });



      if(this.svg){

         let currentProductIndex: number = 0;

         if(this.flight.selectedProductID !== null){
            currentProductIndex = this.productButtons.findIndex((pb) => pb.product.productID === this.flight.selectedProductID);
         }

         if(this.flight.focusedProductID !== null) {
            this.productFocused(this.flight.focusedProductID);
            currentProductIndex = this.productButtons.findIndex((pb) => pb.product.productID === this.flight.focusedProductID);
         }

         let currentPB = this.productButtons[currentProductIndex];

         if(currentPB){
            
            let viewBox: SVGAnimatedRect = this.svg.viewBox;

            viewBox.baseVal.x = currentPB.location.x - (this.tileWidth / 2);
         }

         this.setState({ timeStamp: Date.now()});
      }
   }
   else{
      
      this.setState({ timeStamp: Date.now()});
   }

   }

  onPointerDown(event: MouseEvent | TouchEvent) {
    if(this.svg) {
      this.isPointerDown = true; // We set the pointer as down
      this.didPointerMove = false;
      // We get the pointer position on click/touchdown so we can get the value once the user starts to drag
      this.originalSVGMousePoint = this.convertToSVGPoint(this.getPointFromEvent(event));
    }
  }

  getPointFromEvent (event: MouseEvent | TouchEvent) {
   
   let point: Point = {x:0, y:0};

   if(event instanceof MouseEvent)
   {
      let mouseEvent: MouseEvent = event as MouseEvent;
      point.x = mouseEvent.x;
      point.y = mouseEvent.y;
   }
   else if (event instanceof TouchEvent)
   {
      let touchEvent: TouchEvent = event as TouchEvent;
      point.x = touchEvent.targetTouches[0].clientX;
      point.y = touchEvent.targetTouches[0].clientY;
   }

   return point;
  }


  convertToSVGPoint(screenPoint: Point){
   if(this.svg) {
      // Attempt to translate to the SVG canvas's coordinates.
      let point: SVGPoint = this.svg.createSVGPoint();

      point.x = screenPoint.x;
      point.y = screenPoint.y;


      let screen = this.svg.getScreenCTM();

      if(screen)
      {
        let svgPoint = point.matrixTransform(screen.inverse());

        return {
          x: svgPoint.x,
          y: svgPoint.y
        }
      }
    }
    
    return {
      x: 0, 
      y: 0
    }; 
  }


  onPointerMove (event: MouseEvent | TouchEvent) {
    if(this.svg) {
      // Only run this function if the pointer is down
      if (this.isPointerDown) {

        this.didPointerMove = true;

        // Get the pointer position
        let svgPoint = this.convertToSVGPoint(this.getPointFromEvent(event));

        this.didPointerMoveRight = (this.originalSVGMousePoint.x < svgPoint.x);

        // If more horizontal movement
        if(Math.abs(this.originalSVGMousePoint.y - svgPoint.y) < Math.abs(this.originalSVGMousePoint.x - svgPoint.x)){
         
         // Present vertical scroll from bubbling up
         event.preventDefault();

         // Apply horizontal scroll
         this.svg.viewBox.baseVal.x -= (svgPoint.x - this.originalSVGMousePoint.x);
        
         this.setState({ timeStamp: Date.now()});
        }
        else{
           
           // Accept new origin to reduce horizontal jitter
           this.originalSVGMousePoint = svgPoint;
        }
      }
    }
  }


  onPointerUp(event: MouseEvent | TouchEvent) {
    // The pointer is no longer considered as down
    this.isPointerDown = false;
    
    if(this.didPointerMove)
    {
      // drift back into alignment
      this.driftBack();
    }
  }


  onPointerLeave(event: MouseEvent | TouchEvent) {
      
   if(this.didPointerMove && this.isPointerDown)
   {
      // The pointer is no longer considered as down
      this.isPointerDown = false;

     // drift back into alignment
     this.driftBack();
   }
  }


  onImageClicked(){
   
   if(!this.didPointerMove)
   {
      window.Function("javascript: product.editImage()")?.();

   }
   else{
      //console.log("click abandoned...");
   }
  }


  onProductClicked(product: Flight.Product)
  {
      if(!this.didPointerMove)
      {
            console.log("Product clicked: " + product.name);
            //window.Function("javascript: product.select(" + product.productID + ")")?.();
            
            document.dispatchEvent(new CustomEvent("productClicked", {detail: product.productID}));
      }
      else{
         //console.log("click abandoned...");
      }
  }


  productFocused(productID: number)
  {
   if((this.focusedProductID == null) || (this.focusedProductID !== productID))
   {
      this.focusedProductID = productID;

      //window.Function("javascript: return product.productFocused(" + this.focusedProduct.productID + ")")?.();

      document.dispatchEvent(new CustomEvent("focusedProductChanged", {detail: this.focusedProductID}));
   }
  }


  focusedProductChanged(productID: number)
  {
      if(this.focusedProductID !== null && (productID !== this.focusedProductID))
      {
         this.focusedProductID = null;
         
         this.setState({ timeStamp: Date.now()});
      }
  }


  drawSelectedPB(pb:ProductButton, xCenter: number, actualWidth:number){

   let tlx = pb.location.x - (this.tileWidth / 2.0);
   let tly = pb.location.y;
   let tw = pb.size.w;
   let th = pb.size.h;

   return(
      <>
         <rect x={tlx}  stroke='#FFBF00' strokeWidth={1} fillOpacity={0}  y={tly} width={this.tileWidth} height={this.tileHeight} cursor={this.flight.canClickSelectedProduct ? 'pointer' : 'default'}  onClick={this.flight.canClickSelectedProduct ? ()=>{this.onProductClicked(pb.product)} : () =>{}} />
         
         <image x={tlx + (this.imageInset / 2.0)}   href={"https://www.mybrew.guru" + pb.product.image.scaledURL} y={tly + (this.imageInset / 2.0)} width={pb.size.w - (this.imageInset)} height={pb.size.h - (this.imageInset)} pointerEvents="none" />
      
         
         {this.drawPBDetails(pb, xCenter)}
      </>
   );
  }


  drawFocusedPB(pb:ProductButton, xCenter: number, actualWidth:number){
   
   let tlx = pb.location.x - (this.tileWidth / 2.0);
   let tly = pb.location.y;
   let tw = pb.size.w;
   let th = pb.size.h;

   return(
      <>         
         {this.drawPB(pb, xCenter, actualWidth)}
      </>
   );
  }


  drawPB(pb:ProductButton, xCenter: number, actualWidth:number){
   
   let tlx = pb.location.x - (this.tileWidth / 2.0);
   let tly = pb.location.y;
   let tw = pb.size.w;
   let th = pb.size.h;

   let delta = Math.abs(pb.location.x - xCenter);

   if(this.flight.selectedProductID !== null && delta < this.tileInterval){
      let offset = 10 - (10 / this.tileInterval * Math.abs(delta));
      tlx = tlx + offset * 1.2;
      tly = tly + offset * 1.8;
      th = th - offset / pb.size.w * pb.size.h;
      tw = tw - offset ;
   }

   return(
      <>
         
         <rect x={tlx + (this.shrinkBy / 2.0 )} stroke='#427EC3' strokeWidth={1 - (pb.shrinkBy / this.shrinkBy)}  fillOpacity={1 - (pb.shrinkBy / this.shrinkBy)} fill={'White'}  y={tly + (this.shrinkBy / pb.size.w * pb.size.h / 2.0 )} width={tw - this.shrinkBy} height={th - (this.shrinkBy / tw * th)} cursor={'pointer'} onClick={()=>{this.onProductClicked(pb.product)}} />
         <image x={tlx + (this.shrinkBy / 2.0) + (this.imageInset / 2.0)}   href={ pb.visible ? "https://www.mybrew.guru" + pb.product.image.scaledURL : ''} y={tly + (this.shrinkBy / pb.size.w * pb.size.h / 2.0)  + (this.imageInset / pb.size.w * pb.size.h / 2.0)} width={tw - this.shrinkBy - this.imageInset} pointerEvents="none"   />
         
         {this.drawPBDetails(pb, xCenter)}
      </>
   );
  }

  drawPBDetails(pb:ProductButton, xCenter: number){
   
   let tlx = pb.location.x - (this.tileWidth / 2.0) + 6;
   let tly = pb.location.y;
   let opacity = 1;

   let delta = Math.abs(pb.location.x - xCenter);


   if(this.flight.selectedProductID !== null && pb.product.productID !== this.flight.selectedProductID && delta < this.tileInterval){
      opacity =  (1 / this.tileInterval * Math.abs(delta))
   }


   return(
   <>
      
      <circle cx={tlx}  cy={30} r={6}  fill="white" stroke="black" strokeWidth="0.5" fillOpacity={opacity} strokeOpacity={opacity / 2} z="3" pointerEvents='none' />

      <text x={tlx} y="30"
            fill="Black"
            fillOpacity={opacity}
            textAnchor='middle'
            pointerEvents='none'
      > 
         <tspan fontSize={2}
            dominantBaseline='middle' fontWeight={'bold'}>
            {pb.product.property1} 
         </tspan>
      </text>
      <text x={tlx} y="27.5"
            fill="Black"
            fillOpacity={opacity}
            textAnchor='middle'
            pointerEvents='none'
            display={pb.product.property2 !== '...' ? 'default' : 'none'}
      > 
         <tspan fontSize={2}
            dominantBaseline='middle'>
            {pb.product.property2} 
         </tspan>
         <tspan fontSize={1.5}
            dominantBaseline='middle'>
            {pb.product.property2Units} 
         </tspan>
      </text>

      <text x={tlx} y="32.5"
            fill="Black"
            fillOpacity={opacity}
            textAnchor='middle'
            pointerEvents='none'
            display={pb.product.property3 !== '...' ? 'default' : 'none'}
      > 
         <tspan fontSize={2}
            dominantBaseline='middle'>
            {pb.product.property3} 
         </tspan>
         <tspan fontSize={1.5}
            dominantBaseline='middle'>
            {pb.product.property3Units} 
         </tspan>
      </text>

      {(this.flight.showScores && (pb.product.productID !== this.flight.selectedProductID)) ? (
         <>
            <circle cx={tlx + 8}  cy={40} r={4}  fill="white" stroke="black" strokeWidth="0.25" fillOpacity={opacity} strokeOpacity="0.5" z="3" pointerEvents='none' /> 
            <text x={tlx + 8} y={40}
                  fill="Black"
                  fillOpacity={1.9}
                  textAnchor='middle'
                  pointerEvents='none'
            > 
               <tspan fontSize={2}
                  dominantBaseline='middle'>
                  {Math.round((100 - Math.abs(pb.score)) * 10) / 10} %
               </tspan>
            </text>
         </>
      ):(<></>)}
</>
   );
  }


  driftBack(){
    
    if(!this.isPointerDown && this.svg){
      let viewBox: SVGAnimatedRect = this.svg.viewBox;
      
      let xCenter = viewBox.baseVal.x + viewBox.baseVal.width / 2.0;

      // Calculate the error to the nearest tile interval
      let xError = xCenter - (Math.round(xCenter / this.tileInterval) * this.tileInterval);

      // This adds a velocity effect to the User's finger movement
      // The images will continue to move in the direction of the User's last motion
      if(this.didPointerMoveRight)
      {
         // Dont go past the first product
         let firstPB = this.productButtons.at(0);

         if(firstPB){
            let firstPBCenter = firstPB.location.x;

            if(firstPBCenter > xCenter){
               xError = xCenter - firstPBCenter;
            }
            // Require at least 1/8th movement before snapping to the next
            else if(xError < -(this.tileWidth / 4.0)) {
               xError += this.tileInterval;
            }
         }
      }
      // Move left
      else
      {
         // Dont go past the last product
         let lastPB = this.productButtons.at(this.productButtons.length - 1);

         if(lastPB){
            let lastPBCenter = lastPB.location.x;

            if(lastPBCenter < xCenter){
               xError = xCenter - lastPBCenter;
            }
            // Require at least 1/8th movement before snapping to the next
            else if(xError > (this.tileWidth / 4.0)) {
               xError -= this.tileInterval;
            }
         }
      }

      // Update Buttons based on their location in the view box
      this.productButtons.map((pb: ProductButton, i: number) =>{

         let pbCenter = pb.location.x;

         // The selected Product has a dynamic location, use its actual to identify when it is selected
         if(pb.product.productID === this.flight.selectedProductID) {
            pbCenter = i * this.tileInterval;
         }

         // Check if this makes the Product the current selection
         if(xCenter === pbCenter)
         {
            this.productFocused(pb.product.productID);
         }
      });

      if(xError !== 0){
        if(xError > 0){
         viewBox.baseVal.x = viewBox.baseVal.x - Math.min(xError, Math.max(0.2, (xError / 5)));
        }
        else{
          viewBox.baseVal.x = viewBox.baseVal.x - Math.max(xError, Math.min(-0.2, (xError / 5)));
        }

        this.setState({ timeStamp: Date.now()}, () => {window.requestAnimationFrame(() => this.driftBack())});
      }
      else {
         this.setState({ timeStamp: Date.now()});
      }

    }
  }


  update(){

   if(this.svg){
      
      // apply dynamic image loading to each PB
      let viewBox: SVGAnimatedRect = this.svg.viewBox;
      
      let xCenter = viewBox.baseVal.x + viewBox.baseVal.width / 2.0;
      let actualWidth = this.svg?.clientWidth / this.svg?.clientHeight * viewBox.baseVal.height;
      let actualX = xCenter - actualWidth / 2.0;

      let currentPBDelt = 0;
      let currentProductIndex = 0;
      let first = true;

      this.productButtons.map((pb: ProductButton, i: number) =>{
               
         let pbCenter = pb.location.x ;
         let productPBDelt = pbCenter - xCenter;
         if(pb.product.productID !== this.flight.selectedProductID){
            if(first){
               currentProductIndex = i;
               currentPBDelt = productPBDelt;
               first = false;
            }
            else if( Math.abs(productPBDelt) < Math.abs(currentPBDelt)){
               currentProductIndex = i;
               currentPBDelt = productPBDelt;
            }
         }

         // Do not shrink main product when highlighted
         if(pb.product.productID === this.flight.selectedProductID && this.flight.highlightSelectedProduct){
            pb.shrinkBy = 0;
         }
         // Shrink the focused product
         else if((this.focusedProductID !== null) && (pb.product.productID === this.focusedProductID))
         {
            pb.shrinkBy = Math.min(this.shrinkBy, Math.abs(xCenter - pbCenter));
         }
         // Shrink the rest
         else{
            pb.shrinkBy = this.shrinkBy;
         }
               
      });


      let selectedProductIndex = this.productButtons.findIndex(pb => pb.product.productID === this.flight.selectedProductID);

      let selectedProductButton: ProductButton | null = null;

      if(this.flight.selectedProductID != null){
         selectedProductButton = this.productButtons[selectedProductIndex];
         selectedProductButton.location.x = xCenter;
      }

      this.productButtons.map((pb: ProductButton, i: number) =>{
            
         // If the button is after the focused product
         if(this.flight.selectedProductID !== null){
            if(pb.product.productID !== this.flight.selectedProductID){

               if(currentProductIndex < selectedProductIndex){
                  if(i > currentProductIndex){
                     if(currentProductIndex + 1 === selectedProductIndex){
                        if(currentPBDelt > 0){
                           pb.location.x = ((i - 1) * this.tileInterval)
                        }
                        else{
                           pb.location.x = xCenter + ((i - currentProductIndex - 1) * this.tileInterval)
                        }
                     }
                  }
                  else{
                     pb.location.x = ((i) * this.tileInterval)
                  }
               }
               else if(currentProductIndex > selectedProductIndex){
                  if(i < currentProductIndex){
                     if(currentProductIndex - 1 === selectedProductIndex){

                        if(currentPBDelt < 0){
                           pb.location.x = ((i + 1) * this.tileInterval)
                        }
                        else{
                           pb.location.x = xCenter - ((currentProductIndex - i - 1) * this.tileInterval)
                        }
                     }
                  }
                  else{
                     pb.location.x = ((i) * this.tileInterval)
                  }
               }
               else{
                  //pb.location.x = ((i) * this.tileInterval)
               }
            }
         }
         else{
            pb.location.x = ((i) * this.tileInterval)
         }
         
         // Determine if a PB is visible and needs to be loaded
         if(actualX < (pb.location.x + (pb.size.w / 2))) {   
            if((actualX + actualWidth) > (pb.location.x - (pb.size.w / 2))){
               pb.visible = true;
            }
            else{
               pb.visible = false;
            }
         }
         else{
            pb.visible = false;
         }
      })


      let placeHolderButtons: PlaceHolderButton[] = [];


      if(this.productButtons.length > 0){
         let firstPB: ProductButton = this.productButtons.reduce((prev, curr) => prev.location.x < curr.location.x ? prev : curr);

         for(let lhx = firstPB.location.x - this.tileInterval; lhx + this.tileInterval > actualX; lhx = lhx - this.tileInterval){
            
            let placeHolderButton: PlaceHolderButton = {
               location: {x: lhx, y:2.5},
               size: {w:this.tileWidth, h:this.tileHeight}
            }
   
            placeHolderButtons.push(placeHolderButton);
         }

         let lastPB: ProductButton = this.productButtons.reduce((prev, curr) => prev.location.x > curr.location.x ? prev : curr);

         for(let x = lastPB.location.x + this.tileInterval; x < actualX + actualWidth; x = x + this.tileInterval){
            
            let placeHolderButton: PlaceHolderButton = {
               location: {x: x, y:2.5},
               size: {w:this.tileWidth, h:this.tileHeight}
            }
   
            placeHolderButtons.push(placeHolderButton);
         }
         // Add place holders before first Product Button

      }
      else{      
         // Add place holders
         for(let x = actualX; x < (actualX + actualWidth + this.tileInterval); x = x + this.tileInterval){

            let offset = x < 0 ? x - (x % this.tileInterval) - this.tileInterval : x - (x % this.tileInterval);

            let placeHolderButton: PlaceHolderButton = {
               location: {x: offset, y:2.5},
               size: {w:this.tileWidth, h:this.tileHeight}
            }
            placeHolderButtons.push(placeHolderButton);
         }
      }



      

      return(
         <>
         { (this.flight.selectedProductID !== null) ?(<>{selectedProductButton ? this.drawSelectedPB(selectedProductButton, xCenter, actualWidth) : <></>}</>) : (<></>)
         }
         {this.productButtons.slice(0).reverse().filter(pb => pb.product.productID !== this.flight.selectedProductID).map((pb: ProductButton, i: number) => (
            <>
               {this.drawFocusedPB(pb, xCenter, actualWidth)}
            </>
         ))}
         {
            placeHolderButtons.map(pb =>(
            
            <rect x={pb.location.x + (this.shrinkBy)  - (this.tileWidth / 2.0)} fill='grey' strokeWidth={1}  fillOpacity={0.1} y={pb.location.y + (this.shrinkBy / pb.size.w * pb.size.h)} width={pb.size.w - this.shrinkBy * 2} height={pb.size.h - (this.shrinkBy / pb.size.w * pb.size.h * 2)} cursor={'default'} pointerEvents={'none'} z={0} />
                         
                         
            ))
         }
         </>
      )
   }
  }


  render (){
    return (
      <svg ref={this.svgRef} className='w-100' viewBox={(-this.tileWidth / 2.0) + ' 0 ' + (this.tileWidth) + ' ' + (this.tileHeight + 6)} xmlns="http://www.w3.org/2000/svg" width={'100%'} height={'250px'} >
        {this.update()}
      </svg>
    );
  }

}

export default App;
